import React from "react";
import "../style/style.scss";
import { useTranslation } from "react-i18next";
import NavBar from "../components/common/navbar";
import imgBefore from "../images/before.webp";
import imgAfter from "../images/after.webp";
import imgBeforeAuto from "../images/beforeAuto.webp";
import imgAfterAuto from "../images/afterAuto.webp";
import { Helmet } from "react-helmet";

/**
 * About page
 * @returns undefined
 */
export const AboutPage = () => {
  const { t, i18n } = useTranslation();
  const imageStyle = {
    width: "100%",
    height: "auto",
    maxWidth: "200px"
  };
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>How to use this online picross generator</title>
        {/* <link rel="canonical" href="http://pixacreate.com/en" /> */}
        <meta
          name="description"
          content="This page is to describe the two ways you can create your picross games with this online tool - manual draw and automatic."
        ></meta>
      </Helmet>
      <NavBar />
      <main className="container" role="main">
        <div className="container">
          <h1 className="mt-5">{t("content.aboutPage.title")}</h1>
          <article className="pix-page-default-content">
            <p className="lead">
            {t("content.aboutPage.body")}
            </p>
            <p>{t("content.aboutPage.manual")}</p>
            <div className="row pix-row-h">
              <div className="col-5 pix-row-h">
                <img style={imageStyle} src={imgBefore} alt="before manually drawing manually" />
              </div>
              <div className="col-2 pix-row-h">
                <span style={{ fontSize: 36 }}>&#8594;</span>
              </div>
              <div className="col-5 pix-row-h">
                <img style={imageStyle} src={imgAfter} alt="after drawing manually" />
              </div>
            </div>
            <p>{t("content.aboutPage.automatic")}</p>
            <div className="row pix-row-h">
              <div className="col-5 pix-row-h">
                <img style={imageStyle} src={imgBeforeAuto} alt="user image" />
              </div>
              <div className="col-2 pix-row-h">
                <span style={{ fontSize: 36 }}>&#8594;</span>
              </div>
              <div className="col-5 pix-row-h">
                <img style={imageStyle} src={imgAfterAuto} alt="auto generated picross game" />
              </div>
            </div>
            <a className="my-5" href={`/${i18n.language}/`}>{t("buttons.back")}</a>
          </article>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AboutPage;
